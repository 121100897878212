@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap);

* {
  font-family: 'Rajdhani', sans-serif !important;
  color: #324b54;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #324b54;
  outline: 1px solid slategrey;
}

:root {
  --animate-duration: 2s;
}

html, body {
  margin: 0;

  &.open {
    overflow: hidden;
  }
}

.button {
  transition: all .3s;
  cursor: pointer;
  border: 1px solid #324b54 !important;

  &:hover {
    border: 1px solid #324b54 !important;
    transform: scale(1.02);
    background: white !important;
    color: #324b54 !important;
    transition: all .3s;
  }
}

ul {
  margin: 0;
}

.elementNav {
  transition: all .3s;
  border-bottom: 1px solid white;
  padding-bottom: 5px;

  &.active {
    color: #5ba3c1;
  }

  &:hover {
    transition: all .3s;
    border-bottom: 1px solid black;
    transform: scale(1.01);
  }
}

.offer {
  img {
    transition: all .3s;
    transform: scale(1.10)
  }

  &:hover {
    img {
      transform: rotate(5deg) scale(1.15);
      transition: all .3s;
    }
  }
}

.select, .input {
  label, input {
    color: rgb(18, 26, 68);
    font-family: 'Rajdhani', sans-serif !important;
    font-weight: 700;
    font-size: 18px;
    text-transform: lowercase !important;

    &::placeholder {
      color: white;
    }
  }

  div {
    color: rgb(18, 26, 68);
    font-family: 'Rajdhani', sans-serif !important;
  }
}

.menuNav {
  transition: all .3s;

  &.active {
    background: #324b54;
    color: white !important;

    svg, path {
      color: white;
    }
  }

  &:hover {
    background: #324b54;
    transition: all .3s;
    color: white !important;

    svg, path {
      color: white;
    }
  }
}

.cursor {
  pointer-events: none;

  &__ball {
    position: fixed;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    z-index: 1000;

    circle {
      fill: #f7f8fa;
    }
  }
}

.MuiDialog-paperWidthSm {
  max-width: 800px !important;
}

form {
  width: 100%;
  max-width: 320px;

  > div {
    > div {
      width: 100%;
    }
  }
}

a {
  text-decoration: underline;
  color: #324b54;
}

textarea {
  resize: none;
}

.dialog {
  > div {
    > div {
      @media (max-width: 1024px) {
        &[role="dialog"] {
          margin: 0 !important;
          width: 100% !important;
          height: 100vh !important;
          max-height: none !important;

          form {
            max-width: none !important;
          }
        }
      }
    }
  }
}

.accord {
  svg {
    fill: white;
  }
}

.navigation {
  picture {
    height: 100%;
  }
}

div[role="presentation"] {
  form {
    max-width: none;
  }
}

.event, .game {
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }
}

.chat {
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }
}

.chatRoom {
  transition: all 0.5s;

  &:hover {
    background: rgb(230, 235, 245);
    transition: all 0.5s;
  }
}

.footerTable {
  td {
    width: 100% !important;
  }
}

em-emoji-picker {
  position: absolute;
  margin-top: -463px;
  margin-left: -300px;
  z-index: 1000;
}

.rdw-editor-main {
  border: 1px solid rgba(0, 0, 0, 0.27);
  border-radius: 4px;
  padding: 10px;
  min-height: 350px;
  margin-bottom: 20px;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.87);
  }
}

div {
  &[title="Superscript"] {
    display: none;
  }

  &[title="Subscript"] {
    display: none;
  }

  &[title="Underline"] {
    display: none;
  }

  &[title="Strikethrough"] {
    display: none;
  }

  &[title="Monospace"] {
    display: none;
  }

  &[title="Italic"] {
    display: none;
  }

  &[title="Embedded"] {
    display: none;
  }

  &[title="Indent"] {
    display: none;
  }

  &[title="Outdent"] {
    display: none;
  }

  &[aria-label="rdw-remove-control"] {
    display: none;
  }

  &[aria-label="rdw-image-control"] {
    display: none;
  }

  &[aria-label="rdw-history-control"] {
    display: none;
  }

  &[aria-label="rdw-color-picker"] {
    display: none;
  }

  &[aria-label="rdw-font-size-control"] {
    display: none;
  }

  &[aria-label="rdw-block-control"] {
    display: none;
  }

  &[aria-label="rdw-font-family-control"] {
    display: none;
  }
}

.MuiTooltip-popper {
  > * {
    font-size: 20px !important;
  }
}

.MuiDialog-paperFullWidth {
  max-width: none !important;
  margin: 0 !important;
  width: 100% !important;
  max-height: none !important;
  height: 100vh !important;
}

.css-v1j55f {
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 10px;
    }
  }
}

.rs__popper_actions {
  display: none !important;
}

.fc-today-button {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .fc .fc-scroller-liquid-absolute {
    overflow: hidden !important;
  }

  .fc .fc-view-harness {
    height: 450px !important;
  }
}

.MuiFormHelperText-root {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

.htmlCode span, .htmlCode p {
  font-size: 24px !important;
  background: transparent !important;
  color: rgb(170, 197, 238) !important;
}

.MuiSlider-markLabel {
  color: white !important;
}

.buttonPrimary, #notistack-snackbar {
  color: white !important;

  > * {
    color: white !important;
  }
}

.texts {
  * {
    font-size: 25px !important;
  }
}

.dropzoneComponent {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    color: white !important;
  }
}

.tripsNav {
  &:hover {
    > div {
      display: flex;
    }
  }

  > div {
    &:hover {
      display: flex;
    }
  }
}
